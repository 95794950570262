import React from 'react'

import Responsive from '../../../HOC/Responsive'
import OptimizedImage from '../../../common/OptimizedImage'
import {TrustMarkersWrapper} from './TrustMarkersV2.styles'

function TrustMarkersV2(props) {
  const {
    sectionName = 'trustMarkers',
    cards = [],
    bgColor = '',
    dividerBorderColor = '',
    borderColor = '',
    className = '',
  } = props.state || {}
  const {isMobile = ''} = props

  if (!cards || !cards.length) {
    return null
  }
  return (
    <TrustMarkersWrapper
      data-section={sectionName}
      bgColor={bgColor}
      dividerBorderColor={dividerBorderColor}
      borderColor={borderColor}
      className={className}
    >
      <div className="trustMarkers-container">
        {cards.map((card) => {
          const {
            label = '',
            desktopImg = '',
            mobileImg = '',
            aspectHeight = '',
            aspectWidth = '',
          } = card || {}
          const displayImage = isMobile ? mobileImg : desktopImg

          return (
            <div className="trustmarker-item" key={label}>
              <div className="each-trustmarker">
                <div className="each-trustmarker-image-outer-container">
                  <div className="each-trustmarker-image-inner-container">
                    <OptimizedImage
                      source={displayImage}
                      aspectWidth={aspectWidth}
                      aspectHeight={aspectHeight}
                      desktopViewWidth="SMALL"
                      mobileViewWidth="SMALL"
                      altText="trust-marker"
                      loading="lazy"
                      customClassName="each-trustmarker-image"
                    />
                  </div>
                </div>
              </div>
              <div className="each-trustmarker-label-container">
                <label className="each-trustmarker-label">{label}</label>
              </div>
            </div>
          )
        })}
      </div>
    </TrustMarkersWrapper>
  )
}

export default React.memo(Responsive(TrustMarkersV2))
