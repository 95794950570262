import styled from 'styled-components'

export const TrustMarkersWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 56px;
  padding: 0 16px;

  .trustMarkers-container {
    width: 100%;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    font-family: var(--brand-font-family-text);
    font-style: normal;
    font-weight: var(--brand-font-weight-normal);
    gap: 8px;
    align-items: flex-start;
  }

  .trustmarker-item {
    display: flex;
    width: 104px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .each-trustmarker-image-outer-container {
    height: 104px;
    width: 104px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 16px;
    background-color: ${(props) => props.bgColor};
    border: 1px solid ${(props) => props.borderColor};
    overflow: hidden;
  }

  .each-trustmarker-image-inner-container {
    width: 37px;
    height: 37px;
  }
  .each-trustmarker-image {
    height: auto;
    width: 100%;
    object-fit: contain;
  }

  .each-trustmarker-label-container {
    display: flex;
    justify-content: center;
  }
  .each-trustmarker-label {
    font-size: 14px;
    line-height: 16px;
    font-weight: var(--brand-font-weight-normal);
    text-align: center;
    color: var(--brand-light-secondary-text-color);
    word-break: break-word;
  }

  @media (min-width: 768px) {
    margin-bottom: 96px;

    .trustMarkers-container {
      width: 669px;
      gap: 16px;
    }

    .trustmarker-item {
      width: 200px;
    }

    .each-trustmarker-image-outer-container {
      height: 200px;
      width: 200px;
    }

    .each-trustmarker-image-inner-container {
      width: 72px;
      height: 72px;
    }

    .each-trustmarker-label {
      font-size: 18px;
      line-height: 22px;
    }
  }
`
